<template lang="pug">
Popup

    template(slot="title")

      template(v-if="isEdit") {{ $t('Supplier.popup_Title_Edit') }}

        DualCustomRadio(
          :isActive="form.isActive"
          @delegateIsActiveValue="changeRadioButton"
        )
        .passive-supplier-warning.m-top-5(v-if="!form.isActive") {{ $t('Supplier.popup_Supplier_Warning') }}

      template(v-else) {{ $t('Supplier.popup_Title_Add') }}

    template(slot="content")
      form.Form(
        id="form-supplier"
        @submit.prevent="onSubmitSupplierForm(isEdit)"
      )

        div.Form-item
          .zero
            .zero-checkbox
              CustomCheckbox(
                id="checkbox-supplier-isZeroPriceAvailable"
                label=""
                v-model="form.isZeroPriceAvailable"
              )
            span.zero-span {{ $t('Supplier.popup_Span_isZeroPriceAvailable') }}

            .zero-checkbox.m-left-15
              CustomCheckbox(
                id="checkbox-supplier-isEInvoicePayer"
                label=""
                v-model="form.isEInvoicePayer"
              )
            span.zero-span {{ $t('Supplier.popup_isEInvoiceUser') }}

        div.Form-item.required
          label.Form-item-label {{ $t('Supplier.popup_FormField_Name_Short') }}
          .control

            customTextInput(
              name="name"
              ref="firstField"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('Supplier.popup_FormField_Name')"
              v-validate="'required|max:128|verify_name'"
              id="input-supplier-form-field-name"
              v-model="form.name"
              :isDelayInput="true"
            )

            Button(
              id="btn-supplier-popup-loading-name",
              tabindex="-1",
              v-show="isPendingName"
              variant="icon loading"
            )
              Loading(theme="disable")

            Button(
              id="btn-supplier-popup-ok-name",
              tabindex="-1",
              v-show="isValidateName"
              variant="icon check"
              iconName="icon-check"
              iconClass="icon-check"
              :justIcon="true"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        div.Form-item
          label.Form-item-label
            | {{ $t('Supplier.detail_Popup_FormField_SupplierGroup') }}

          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="supplierGroups"
              :optionData="supplierGroups"
              v-model="form.supplierGroupId"
              id="select-supplier-popup-supplier-group"
              optionIdName="option-suppliers-popup-group-name"
            )

        div.Form-item
          label.Form-item-label
            | {{ $t('Supplier.detail_Popup_FormField_Currency') }}

          .control.form-item-select

            Icon(name="icon-down-arrow")

            customSelectInput(
              name="currency"
              optionName="isoCode"
              :disabled="isEdit"
              :optionData="CurrencyList"
              v-model="form.currencyId"
              id="input-group-index-form-item-hasMajorGroups"
              optionIdName="option-suppliers-popup-currency-name"
            )

        div.Form-item.required(v-if="RoleArea === 'local' && !isEdit")
            label.Form-item-label(id="label-store-group") {{ $t('Supplier.popupForm_Field_Permitted_StoreGroups') }}

            CustomMultiSelectList(
              id="custom-multiselect-list-store-group-supplier-form"
              :items="permittedStoreGroupList"
              :selected="form.storeGroupList"
              :isValid="errorForStoreGroups && !isLoading"
              :disablePopup="isSinglePermittedStoreGroup"
              @delegateCheckItem="selectStoreGroup"
            )

            p.Form-item-help.is-danger(
              v-show="errorForStoreGroups && !isLoading"
            ) {{ $t('Supplier.popupForm_Error_Permitted_StoreGroups') }}

        div.Form-item(v-show="!showTaxNumberInput")
          label.Form-item-label(id="label-supplier-form-brach-code") {{ $t('Supplier.popup_FormField_branchCode') }}
          .control
            customTextInput(
              name="branchCode"
              :data-vv-as="$t('Supplier.popup_FormField_branchCode')"
              id="input-supplier-form-branch-code"
              v-model="form.supplierBranchCode"
              :error="veeErrors.has('branchCode')"
              v-validate="'max:50'"
            )
            showValidateError(
              v-show="veeErrors.has('branchCode')"
              :errorName="veeErrors.first('branchCode')"
            )

        div.Form-item(v-show="showTaxNumberInput")
          label.Form-item-label(id="label-supplier-form-tax-number") {{ $t('Supplier.popup_FormField_TaxNumber') }}
          .control
            customTextInput(
              name="taxNumber"
              :data-vv-as="$t('Supplier.popup_FormField_TaxNumber')"
              id="input-supplier-form-tax-number"
              v-model="form.taxNumber"
              :error="veeErrors.has('taxNumber')"
              v-validate="'max:11'"
            )
            showValidateError(
              v-show="veeErrors.has('taxNumber')"
              :errorName="veeErrors.first('taxNumber')"
            )

        div.Form-item

          label.Form-item-label {{ $t('Supplier.popup_FormField_deferredDay') }}

          .control

            customNumberInput(
              name="deferredDay"
              :error="veeErrors.has('deferredDay')"
              :data-vv-as="$t('Supplier.popup_FormField_deferredDay')"
              v-validate="'min_value:1'"
              id="input-supplier-form-account-deferred-day"
              v-model="form.deferredDay"
            )

            showValidateError(
              v-show="veeErrors.has('deferredDay')"
              :errorName="veeErrors.first('deferredDay')"
            )

        div.Form-item

          label.Form-item-label {{ $t('Supplier.popup_FormField_Address') }}

          .control

            textarea.txt(
              name="address"
              rows="2"
              :data-vv-as="$t('Supplier.popup_FormField_Address_Short')"
              v-model="form.address"
              v-validate="'max:512'"
              :class="{ 'is-danger': veeErrors.has('address') }"
              type="text"
              id="input-supplier-form-address-short"
            )

            showValidateError(
              v-show="veeErrors.has('address')"
              :errorName="veeErrors.first('address')"
            )

        div.Form-item

          label.Form-item-label {{ $t('Supplier.popup_FormField_EmailAddress') }}

          .control

            .input-listing
              .input-listing-add
                input.txt(
                  ref="inputList"
                  autocomplete="off"
                  name="emailAddress"
                  type="text"
                  id="input-supplier-form-email-address"
                  :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
                  v-model="emailInput"
                  v-validate="'email|verify_email'",
                  data-vv-scope="emailScope"
                  @keydown.enter.submit.prevent="addItem"
                  :data-vv-as="$t('Supplier.popup_FormField_EmailAddress')"
                  :class="{ 'is-danger': veeErrors.has('emailAddress') }",
                )
                Button(
                  variant="icon global",
                  id="btn-input-listing-add",
                  iconName="icon-global-add"
                  iconClass="icon-global-add"
                  @click ="addItem"
                  v-show="!isPendingEmail"
                )
                Button(
                  variant="icon loading"
                  id="btn-supplier-popup-loading-email"
                  tabindex="-1",
                  v-show="isPendingEmail"
                )
                  Loading(theme="disable")
                showValidateError(
                  v-show="veeErrors.has('emailScope.emailAddress')"
                  :errorName="veeErrors.first('emailScope.emailAddress')"
                  id="input-listing-add-error"
                )

              .input-listing-items(v-if="emailList.length > 0")
                .item-list(v-for="(item, index) in emailList")
                  span.item {{ item }}
                  Button(
                    variant="icon close",
                    :id="`input-listing-remove-${index}`",
                    iconName="icon-popup-close-withCurrentColor"
                    iconClass="icon-popup-close-withCurrentColor"
                    :justIcon="true"
                    @click ="removeItem(item)"
                  )
        div.Form-item
          label.Form-item-label {{ $t('Supplier.popup_FormField_ContactEmailAddress') }}
          .control
            .input-listing
              .input-listing-add
                customTextInput(
                  ref="inputList"
                  name="contactEmailAddress"
                  id="input-supplier-form-contact-email-address"
                  :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
                  v-model="contactEmailInput"
                  v-validate="'email|verify_email'",
                  data-vv-scope="emailScope"
                  @keydown.enter.submit.prevent="addContactEmail"
                  :data-vv-as="$t('Supplier.popup_FormField_ContactEmailAddress')"
                  :class="{ 'is-danger': veeErrors.has('contactEmailAddress') }",
                )
                Button(
                  variant="icon global",
                  id="btn-input-listing-add",
                  iconName="icon-global-add"
                  iconClass="icon-global-add"
                  @click ="addContactEmail"
                  v-show="!isPendingContactEmail"
                )
                Button(
                  variant="icon loading"
                  id="btn-supplier-popup-loading-email"
                  tabindex="-1",
                  v-show="isPendingContactEmail"
                )
                  Loading(theme="disable")
          
                showValidateError(
                  v-show="veeErrors.has('emailScope.contactEmailAddress')"
                  :errorName="veeErrors.first('emailScope.contactEmailAddress')"
                  id="input-listing-add-error"
                )

              .input-listing-items(v-if="contactEmailList.length > 0")
                .item-list(v-for="(item, index) in contactEmailList")
                  span.item {{ item }}
                  Button(
                    variant="icon close",
                    :id="`input-listing-remove-${index}`",
                    iconName="icon-popup-close-withCurrentColor"
                    iconClass="icon-popup-close-withCurrentColor"
                    :justIcon="true"
                    @click ="removeContactEmailItem(item)"
                  )

        div.Form-item

          label.Form-item-label {{ $t('Supplier.popup_FormField_PhoneNumber') }}

          .control

            customTextInput(
              name="phoneNumber"
              v-validate="'max:50'"
              :data-vv-as ="$t('Supplier.popup_FormField_PhoneNumber')"
              v-model="form.phoneNumber"
              :error="veeErrors.has('phoneNumber')"
              id="input-supplier-form-field-phoneNumber"
            )

            showValidateError(
              v-show="veeErrors.has('phoneNumber')"
              :errorName="veeErrors.first('phoneNumber')"
            )

        div.Form-item
          label.Form-item-label {{ $t('Supplier.popup_FormField_ContactName') }}
          .control

            customTextInput(
              name="contactName"
              :data-vv-as="$t('Supplier.popup_FormField_ContactName')"
              v-model="form.contactName"
              :error="veeErrors.has('contactName')"
              v-validate="'max:64'"
              id="input-supplier-form-contact-name"
            )

            showValidateError(
              v-show="veeErrors.has('contactName')"
              :errorName="veeErrors.first('contactName')"
            )


    template(slot="footer")
      div.Form-item.m-bottom-15(v-if="checkPermission('SupplierUsageWithListIsNotRequiredEdit')")
        CustomCheckbox(
          id="checkbox-supplier-usageWithListIsNotRequired"
          :label="$t('Supplier.popup_FormField_usageWithListIsNotRequired')"
          v-model="form.usageWithListIsNotRequired"
          )
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
          id="checkbox-supplier-save-and-new"
          :label="$t('Global.formSaveAndNewText', { form: $t('Supplier.popup_FormName') })"
          v-model="saveAndNew"
        )

      Button(
        size="medium"
        primary
        variant="full",
        id="btn-supplier-popup-submit",
        type="submit",
        form="form-supplier",
        :disabled="isLoading",
        :isLoading="isLoading"
      )
        span
          template(v-if="isEdit") {{ $t('Supplier.popup_Button_Edit') }}
          template(v-else) {{ $t('Supplier.popup_Button_Add') }}

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields, Validator } from 'vee-validate'
import CustomMultiSelectList from '@/view/global/custom-multiselect-list'
import DualCustomRadio from '@/view/global/dual-custom-radio'
import { getObjectValuesByKey, omit, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'SupplierForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  components: {
    CustomMultiSelectList,
    DualCustomRadio
  },

  data () {
    return {
      emailInput: null,
      contactEmailInput: null,
      emailList: [],
      contactEmailList: [],
      form: {
        id: null,
        name: null,
        taxNumber: null,
        address: null,
        emailAddress: null,
        contactEmailAddress: null,
        phoneNumber: null,
        contactName: null,
        deferredDay: null,
        isGlobal: null,
        isActive: true,
        supplierGroupId: null,
        storeGroupList: [],
        isZeroPriceAvailable: false,
        currencyId: null,
        isEInvoicePayer: false,
        supplierBranchCode: null,
        usageWithListIsNotRequired: false
      },
      saveAndNew: true,
      defaultStoreGroup: null,
      permittedStoreGroupList: []
    }
  },

  created () {
    const self = this
    this.setRoleArea()
    this.getSupplierGroups()
    if (!this.isEdit) this.form.currencyId = this.Tenant.baseCurrencyId

    this.getPermittedStoreGroup()
      .then(res => {
        this.permittedStoreGroupList = res.data.permittedStoreGroupList
        this.permittedStoreGroupList.forEach(permitted => {
          if (permitted.isDefault) {
            this.defaultStoreGroup = permitted
            if (!self.isEdit) {
              this.form.storeGroupList.push(permitted)
            }
          }
        })
      })

    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let storeGroupIds = self.isEdit ? self.form.storeGroups.map(storeGroup => storeGroup.id) : self.form.storeGroupList.map(storeGroup => storeGroup.id)
          let params = {
            name: value,
            storeGroupIds: self.RoleArea === 'local' ? storeGroupIds : null,
            id: self.isEdit ? self.form.id : null
          }
          self.checkSupplierName(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => 'Bu ad ile daha önce kayıt yapılmış.'
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)

    const verifyEmail = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            EmailAddress: value
          }
          if (self.isEdit) {
            params.Id = self.form.id
          }
          self.checkSupplierEmail(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyEmailMessages = {
      tr: {
        messages: {
          verify_email: field => 'Bu email ile daha önce kayıt yapılmış.'
        }
      }
    }

    Validator.extend('verify_email', verifyEmail)
    Validator.localize(verifyEmailMessages)
  },

  beforeMount () {
    if (this.isEdit) {
      let id = this.$route.params.id

      this.getSupplier(id).then(res => {
        if (res.status === 200) {
          this.form = res.data.supplier
          if (this.form.emailAddress) this.emailList = this.form.emailAddress.split(';')
          if (this.form.contactEmailAddress) this.contactEmailList = this.form.contactEmailAddress.split(';')
        }
      })
    }
  },

  mounted () {
    this.formFocus()
  },

  computed: {
    ...mapGetters('Supplier', [
      'suppliersListPagination',
      'RoleArea'
    ]),

    ...mapGetters('Settings', [
      'RoleArea', 'checkPermission'
    ]),

    ...mapGetters('SupplierGroups', [
      'supplierGroups'
    ]),

    ...mapGetters('Global', [
      'CurrencyList',
      'activeCurrency'
    ]),

    ...mapGetters('Auth', ['Tenant']),

    ...mapFields({
      flagsName: 'name',
      flagsEmailAddress: 'emailScope.emailAddress',
      flagsContactEmailAddress: 'emailScope.contactEmailAddress'
    }),

    showTaxNumberInput () {
      return this.form.supplierGroupId === null
    },

    newSupplier () {
      let supplier = {}
      Object.keys(this.form).forEach((item) => {
        if (this.form[item] !== null) {
          supplier[item] = this.form[item]
        }
      })
      return supplier
    },

    isLoading () {
      return this.$wait.is([ 'setRoleArea', 'getSupplier', 'updateSupplier', 'createSupplier', 'checkSupplierEmail', 'getPermittedStoreGroup', 'supplierHeaderSubmitForm' ])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid &&
                 !this.isPendingName
      } else {
        return this.flagsName.valid &&
                 this.form.name !== '' &&
                 this.form.name !== null &&
                 !this.isPendingName
      }
    },

    isPendingEmail () {
      return this.flagsEmailAddress.pending
    },

    isPendingContactEmail () {
      return this.flagsContactEmailAddress.pending
    },

    isSinglePermittedStoreGroup () {
      return this.permittedStoreGroupList.length <= 1
    },

    isValidateEmail () {
      if (this.flagsEmailAddress.required) {
        return this.flagsEmailAddress.valid &&
                 !this.isPendingEmail
      } else {
        return this.flagsEmailAddress.valid &&
                 this.form.emailAddress !== null &&
                 this.form.emailAddress !== '' &&
                 !this.isPendingEmail
      }
    },

    errorForStoreGroups () {
      return this.isEdit ? false : this.form.storeGroupList.length === 0
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Settings', [
      'fetchPermissionRoleList',
      'getPermittedStoreGroup',
      'setRoleArea'
    ]),

    ...mapActions('Supplier', [
      'getSuppliers',
      'getSupplier',
      'createSupplier',
      'updateSupplier',
      'checkSupplierName',
      'checkSupplierEmail'
    ]),

    ...mapActions('SupplierGroups', [
      'getSupplierGroups'
    ]),

    selectStoreGroup (event) {
      if (event.isChecked) {
        this.form.storeGroupList.push(event.item)
      } else {
        this.form.storeGroupList.splice(this.form.storeGroupList.indexOf(event.item), 1)
      }
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    resetForm () {
      this.form.id = null
      this.form.name = null
      this.form.taxNumber = null
      this.form.supplierGroupId = null
      this.form.deferredDay = null
      this.form.address = null
      this.form.emailAddress = null
      this.form.contactEmailAddress = null
      this.emailInput = null,
      this.contactEmailInput = null,
      this.form.phoneNumber = null
      this.form.contactName = null
      this.form.storeGroupList = []
      this.emailList = []
      this.isEInvoicePayer = false
      this.form.usageWithListIsNotRequired = false
      this.form.supplierBranchCode = null
      this.permittedStoreGroupList.map(item => {
        if (item.isDefault) this.form.storeGroupList.push(item)
      })
    },

    onSubmitSupplierForm: vueWaitLoader(async function (isEdit) {
      await this.$validator.validateAll().then(async result => {
        if (!result || this.errorForStoreGroups) return
        if (isEdit) {
          return this.submitEdit()
        }
        return this.submitNew()
      })
    }, 'supplierHeaderSubmitForm'),

    async submitNew () {
      if (this.form.supplierGroupId) this.form.taxNumber = null
      if (!this.form.supplierGroupId) this.form.supplierBranchCode = null
      let supplier = this.newSupplier
      supplier.storeGroupList = supplier.storeGroupList.map(storeGroup => storeGroup.id)
      supplier.isZeroPriceAvailable = this.form.isZeroPriceAvailable
      supplier.isEInvoicePayer = this.form.isEInvoicePayer
      supplier.emailAddress = this.form.emailAddress
      supplier.contactEmailAddress = this.form.contactEmailAddress
      supplier.usageWithListIsNotRequired = this.form.usageWithListIsNotRequired

      await this.createSupplier(supplier).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: supplier.name })
          this.notifyShow({ message })
          this.getSuppliers({pageNumber: this.suppliersListPagination.number, pageSize: this.suppliersListPagination.size, isActive: true})
          if (this.saveAndNew) {
            this.resetForm()
            this.formFocus()
            this.$nextTick(() => {
              this.$validator.reset()
            })
          } else {
            this.close()
          }
        }
      })
    },

    async submitEdit () {
      if (this.form.supplierGroupId) this.form.taxNumber = null
      if (!this.form.supplierGroupId) this.form.supplierBranchCode = null
      let supplier = this.newSupplier
      supplier = omit(supplier, ['storeGroups'])
      supplier.isActive = this.form.isActive
      supplier.isZeroPriceAvailable = this.form.isZeroPriceAvailable
      supplier.isEInvoicePayer = this.form.isEInvoicePayer
      supplier.usageWithListIsNotRequired = this.form.usageWithListIsNotRequired
      const payload = getObjectValuesByKey(supplier, ['id', 'name', 'taxNumber', 'address', 'emailAddress', 'contactEmailAddress', 'phoneNumber', 'contactName', 'deferredDay', 'isActive', 'supplierGroupId', 'isZeroPriceAvailable', 'currencyId', 'isEInvoicePayer', 'supplierBranchCode', 'usageWithListIsNotRequired'])
      await this.updateSupplier(payload).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: supplier.name })
          this.notifyShow({ message })
          this.getSuppliers({pageNumber: this.suppliersListPagination.number, pageSize: this.suppliersListPagination.size, isActive: true})
          this.close()
        }
      })
    },

    changeRadioButton (value) {
      this.form.isActive = value
    },

    addItem () {
      this.$validator.validateAll('emailScope').then(result => {
        if (this.emailInput) {
          if (!result) return
          this.emailList.push(this.emailInput)
          this.emailInput = ''
          this.onChangeListing()
        }
      })
    },

    removeItem (item) {
      this.emailList.splice(this.emailList.findIndex((element) => element === item), 1)
      this.onChangeListing()
    },

    onChangeListing () {
      this.form.emailAddress = this.emailList.join(';')
    },
    addContactEmail () {
      this.$validator.validateAll('emailScope').then(result => {
        if (this.contactEmailInput) {
          if (!result) return
          this.contactEmailList.push(this.contactEmailInput)
          this.contactEmailInput = ''
          this.onChangeContactEmailListing()
        }
      })
    },
    onChangeContactEmailListing () {
      this.form.contactEmailAddress = this.contactEmailList.join(';')
    },

    removeContactEmailItem (item) {
      this.contactEmailList.splice(this.contactEmailList.findIndex((element) => element === item), 1)
      this.onChangeContactEmailListing()
    }
  }
}
</script>

<style lang="scss" scoped>

.zero {
  display: flex;
  align-items: center;
  width: 100%;
  height: 41px;
  border: 1px solid $color-gray;
  background-color: #fff;
  border-radius: $border-radius;

  &-span {
    margin-left: 10px;
    font-size: $font-size-small;
    color: $color-dark;
    font-family: $font-family;
  }

  &-checkbox {
    margin-left: 10px;
  }
}

.input-listing {
  &-add {
    .global, .loading {
      color: $color-success;
      z-index: $z-index-md;
      position: absolute;
      top: 5px;
      right: 0;
      transform: translateY(0);
    }
  }

  &-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    .item-list {
      display: flex;
      align-items: center;
      background-color: $color-white;
      border: solid 1px $color-gray;
      border-radius: $border-radius;
      margin-top: 10px;
      margin-left: 10px;
      padding-left: 10px;
      .item {
        color: $color-sidebar-dark;
        font-size: $font-size-normal;
      }
      .close {
        color: $color-red;
      }
    }
  }
}
.passive-supplier-warning {
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  color: $color-warning;
}
</style>
